import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link
import '../assets/css/Stake.css';
import starts_1 from '../assets/images/header.png';

function Stake() {
    const [inputValue, setInputValue] = useState('');
    const [fontSize, setFontSize] = useState(42); // Начальный размер шрифта
    const inputRef = useRef(null); // ref для поля ввода

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        adjustFontSize(value);
    };

    // Функция для динамического уменьшения шрифта
    const adjustFontSize = (text) => {
        if (inputRef.current) {
           
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Устанавливаем фокус на поле ввода
        }
        if (window.Telegram && window.Telegram.WebApp) {
            // Ожидаем загрузки WebApp
            window.Telegram.WebApp.ready();
    
            // Скрываем кнопку выхода и отображаем кнопку "Назад"
            window.Telegram.WebApp.MainButton.hide(); // Скрываем стандартную кнопку
    
            // Настроим Telegram для добавления кнопки назад
            window.Telegram.WebApp.BackButton.show(); // Показываем кнопку "Назад"
            window.Telegram.WebApp.BackButton.onClick(function () {
                window.history.back(); // Осуществляем переход назад
            });
            window.Telegram.WebApp.MainButton.setParams({
                text: "Continue", // Текст на кнопке
                color:"#d5ddff",
                text_color:"#5678FF",
                backgroundColor:"#fff",
                is_active: false, // Кнопка активна
                is_visible: true // Кнопка видна
            });
        }
        // Вызов функции сразу при загрузке компонента
        adjustFontSize(inputValue);
    }, []);



    return (
        <>
            <div className="Key-top border">
                <div className="Key-top-el ">
                    <div className="Key-top-el-keyflex">
                        <div className="Key-footer-el-key StakeToken-key-stake key">Stake</div>
                        <div className="Key-footer-el-key StakeToken-key-Unstake key">Unstake</div>
                    </div>
                </div>
            </div>
            <div className="stake">
                <div className="container">
                    <div className="StakeCount">
                        <div className="StakeInput">
                            <input
                                ref={inputRef}
                                type="number"
                                placeholder="0"
                                autocomplete="off"
                                inputmode="decimal"
                                value={inputValue}
                                onChange={handleInputChange}
                                style={{ fontSize: `${fontSize}px` }} // Применяем динамический размер шрифта
                            />
                            
                        </div>
                        
                    </div>
                    <div className="StakeTokenName">NOT</div>
                </div>
                <div className="Key-footer border">
                    <div className="Key-footer-el ">
                        <div className="text-footer">
                            <div className="text-footer-count">123213123123 NOT</div>
                            <div className="text-footer-cam">Unstake</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Stake;
