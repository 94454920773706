import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link
import logo_token from '../assets/images/uebit.jpg';
import logo_not from '../assets/images/not.png';  
import icon_user from '../assets/images/icon_user.svg';
import icon_fire from '../assets/images/e52bc7dc-1a87-42b3-abda-9c3df00aa42e.gif';
import logo_creater from '../assets/images/photo_2024-06-29_13-36-49 (3).jpg';

function TopPool() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      const timerAnim = setTimeout(() => {
        setActiveIndex((prev) => (prev + 1) % pools.length);
        setFadeOut(false);
        clearTimeout(timerAnim);
      }, 450); // Таймер на 450 миллисекунд для анимации

    }, 9000); // Меняем активный элемент каждые 9 секунд

    const timer = setTimeout(() => {
      setLoading(false); // Смена состояния через 2.4 секунды
    }, 2400);

    return () => {
      setFadeOut(false);
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  const pools = [
    {
      id: 1,
      title: 'NOTCOIN',
      tokenAmount: '2,200,000 NOT',
      rewardPool: 'Reward pool',
      holdersCount: 10,
      logo: logo_not,
      creatorName: 'NOTCOIN',
      creatorAvatar: logo_not,
      creatorTime: '10 seconds ago',
    },
    {
      id: 2,
      title: 'UEBIT',
      tokenAmount: '2,200 UEBIT',
      rewardPool: 'Reward pool',
      holdersCount: 10,
      logo: logo_token,
      creatorName: '🍺 ПИВАНЧЕС',
      creatorAvatar: logo_token,
      creatorTime: '1 minute ago',
    },
    {
      id: 3,
      title: 'EXAMPLECOIN',
      tokenAmount: '3,000,000 EXC',
      rewardPool: 'Reward pool',
      holdersCount: 20,
      logo: logo_token,
      creatorName: 'JaneDoe',
      creatorAvatar: logo_creater,
      creatorTime: '5 minutes ago',
    },
  ];

  return (
    <div className="container-pool">
      <div className="pool-item">
        <div className="pool-element border">
          <div className="pool-top-header">
            <div className="title-page title-top">
              <img src={icon_fire} className="top-pool-icon-fire" alt="logo" />
              TOP POOL
            </div>
            <div className="pool-top-header-list">
              {pools.map((_, index) => (
                <div
                  key={index}
                  className={`pool-top-header-element ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => handleClick(index)}
                ></div>
              ))}
            </div>
          </div>

          {/* Условный рендеринг блока с скелетоном или реальными данными */}
          {loading ? (
            <div className="fade-in">
              <div className="pool-element-1">
                <div className="pool-element-2">
                  <div className="title-token skeleton"></div>
                  <div className="pool-element-token-pool skeleton"></div>
                  <div className="pool-element-token-pool-t skeleton"></div>
                  <div className="pool-element-pool-holders skeleton"></div>
                </div>
                <div className="pool-element-3 skeleton"></div>
              </div>
              <div className="pool-element-footer">
                <div className="pool-element-5">
                  <div className="pool-element-creater">
                    <div className="pool-element-creater-avatar skeleton"></div>
                    <div className="pool-element-creater-info">
                      <div className="pool-element-creater-name skeleton"></div>
                      <div className="pool-element-creater-time skeleton"></div>
                    </div>
                  </div>
                </div>
                <div className="pool-element-4">
                  <div className="pool-element-key-stake key skeleton"></div>
                </div>
              </div>
            </div>
          ) : (
            <div className={`top-element ${fadeOut ? 'fade-outtop' : 'fade-intop'}`}>
              <div className="pool-element-1">
                <div className="pool-element-2">
                  <div className="title-token">{pools[activeIndex].title}</div>
                  <div className="pool-element-token-pool">{pools[activeIndex].tokenAmount}</div>
                  <div className="pool-element-token-pool-t">{pools[activeIndex].rewardPool}</div>
                  <div className="pool-element-pool-holders">
                    <img src={icon_user} className="pool-element-pool-icon-holder" alt="logo" /> {pools[activeIndex].holdersCount}
                  </div>
                </div>
                <div className="pool-element-3">
                  <img src={pools[activeIndex].logo} className="icon-token pool-element-icon" alt="logo" />
                </div>
              </div>
              <div className="pool-element-footer">
                <div className="pool-element-5">
                  <div className="pool-element-creater">
                    <div className="pool-element-creater-avatar">
                      <img src={pools[activeIndex].creatorAvatar} alt="logo" />
                    </div>
                    <div className="pool-element-creater-info">
                      <div className="pool-element-creater-name">{pools[activeIndex].creatorName}</div>
                      <div className="pool-element-creater-time">{pools[activeIndex].creatorTime}</div>
                    </div>
                  </div>
                </div>
                <div className="pool-element-4">
                  <Link to="/stake" className="pool-element-key-stake key">
                    STAKE
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopPool;
