import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link
import '../assets/css/NewPoolPage.css';
import starts_1 from '../assets/images/header.png';
function NewPoolPage() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    // Данные для поиска, например, список категорий или названий
    const poolOptions = [
        'Bitcoin Pool',
        'Ethereum Pool',
        'Litecoin Pool',
        'Dogecoin Pool',
        'Polkadot Pool',
        'Solana Pool'
    ];

    const [searchQuery, setSearchQuery] = useState(''); // Хранение введенного текста
    const [filteredOptions, setFilteredOptions] = useState(poolOptions); // Фильтровые результаты
    const [poolDescription, setPoolDescription] = useState(''); // Хранение описания пула

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Фильтруем варианты на основе введенного текста
        const filtered = poolOptions.filter(option =>
            option.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const handleOptionSelect = (option) => {
        setSearchQuery(option); // Устанавливаем выбранное значение в поле ввода
        setFilteredOptions([]); // Очищаем список предложений после выбора
    };

    const handleDescriptionChange = (event) => {
        setPoolDescription(event.target.value); // Обновляем описание пула
    };

    if (window.Telegram && window.Telegram.WebApp) {
        // Ожидаем загрузки WebApp
        window.Telegram.WebApp.ready();

        // Скрываем кнопку выхода и отображаем кнопку "Назад"
        window.Telegram.WebApp.MainButton.hide(); // Скрываем стандартную кнопку

        // Настроим Telegram для добавления кнопки назад
        window.Telegram.WebApp.BackButton.show(); // Показываем кнопку "Назад"
        window.Telegram.WebApp.BackButton.onClick(function () {
            window.history.back(); // Осуществляем переход назад
        });
        window.Telegram.WebApp.MainButton.setParams({
            text: "Create Pool", // Текст на кнопке
            color:"#d5ddff",
            text_color:"#5678FF",
            backgroundColor:"#fff",
            is_active: true, // Кнопка активна
            is_visible: true // Кнопка видна
        });

    }

    return (
        <>
        <header className="App-header">
            <img src={starts_1} alt="logo" />
        </header>
        <div className="container">
            <div className="titleNewPoolC">
                <div className="titleNewPoolNumber">1</div>
                <div className="titleNewPoolTitle">Information about your pool</div>
            </div>

            {/* Поле для поиска пула */}
            <div className="input-container">
                <div className="input-title-container">
                    <div className="input-title">Address token</div>
                    <div className="input-count">50</div>
                </div>
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search for a pool..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />

                {/* Показ предложений при наличии фильтров */}
                {filteredOptions.length > 0 && (
                    <ul className="search-suggestions">
                        {filteredOptions.map((option, index) => (
                            <li
                                key={index}
                                className="search-suggestion"
                                onClick={() => handleOptionSelect(option)}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* Поле для ввода описания пула */}
            <div className="input-container">
                <div className="input-title-container">
                    <div className="input-title">Pool Description</div>
                    <div className="input-count">255</div>
                </div>
                <textarea
                    className="description-input"
                    placeholder="Enter a description for your pool"
                    value={poolDescription}
                    onChange={handleDescriptionChange}
                />
            </div>

        
        </div>
        </>
    );
}

export default NewPoolPage;
