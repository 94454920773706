import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {TonConnectUIProvider} from '@tonconnect/ui-react'; 
import './assets/css/App.css';
import './assets/css/home.css';
import Home from './components/Home';
import StakePage from './components/StakePage';
import NewPoolPage from './components/NewPoolPage';
function App() {
  function adjustForNotch() {
      if (window.innerHeight / window.innerWidth > 1.7) {
          // Если у устройства есть челка, добавляем отступ
          document.documentElement.style.setProperty('--safe-area', 'env(safe-area-inset-bottom)');
          document.documentElement.style.setProperty('--safe-area-bottom', 'env(safe-area-inset-bottom)');
      } else {
          // Если нет, можно установить стандартный отступ
          document.documentElement.style.setProperty('--safe-area', '16px');
          document.documentElement.style.setProperty('--safe-area-bottom', '8px');
      }
  }

  adjustForNotch();

  return (
    <TonConnectUIProvider manifestUrl="https://test.legochat.ru/tonconnect-manifest.json">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stake" element={<StakePage />} />
          <Route path="/new-pool" element={<NewPoolPage />} />
        </Routes>
      </Router>
    </TonConnectUIProvider>
    
  );
}

export default App;
