import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link
import loader_icon from '../assets/images/Orloader.gif';

function Loader() {
    return (
        <div className="LoaderCon">
            <div className="LoaderElement">
                <img src={loader_icon} alt="loader" loading="lazy" />
                <div className="Loader-Text">Loading<span className="dots"></span></div>
            </div>
        </div>
    );
}

export default Loader;
