import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link
import logo_token from '../assets/images/uebit.jpg';  
import logo_not from '../assets/images/not.png';  
import icon_user from '../assets/images/icon_user.svg';  
import logo_creater from '../assets/images/photo_2024-06-29_13-36-49 (3).jpg';  

function PoolList({ activeFilter }) {
  // Состояние для управления загрузкой данных
  const [loading, setLoading] = useState(true);

  // Эффект для обновления загрузки в зависимости от фильтра
  useEffect(() => {
    setLoading(true);  // При смене фильтра всегда показываем скелетон
    const timer = setTimeout(() => {
      setLoading(false); // По истечении 2 секунд изменяем состояние на false
    }, 2400); // 2400 миллисекунд = 2.4 секунды

    return () => clearTimeout(timer);  // Очищаем таймер при размонтировании компонента
  }, [activeFilter]);  // Зависимость от activeFilter

  const pools = [
    {
      id: 1,
      title: 'NOTCOIN',
      tokenAmount: '2 200 000 NOT',
      rewardPool: 'Reward pool',
      holdersCount: 10,
      logo: logo_not,
      creatorName: 'LilNLuv',
      creatorAvatar: logo_creater,
      creatorTime: '10 seconds ago',
    },
    {
      id: 2,
      title: 'UEBIT',
      tokenAmount: '5 000 000 UEBIT',
      rewardPool: 'Reward pool',
      holdersCount: 15,
      logo: logo_token,
      creatorName: 'JohnDoe',
      creatorAvatar: logo_creater,
      creatorTime: '1 minute ago',
    },
    // Добавьте другие пулы по аналогии
  ];

  return (
    <div className="pool-item">
      {/* Когда loading истинно, показываем скелетон, когда ложь - реальные данные */}
      {loading ? (
        <>
          {/* Рендерим элементы скелетона */}
          <div className="pool-element border">
            <div className="pool-element-1">
              <div className="pool-element-2">
                <div className="title-token skeleton"></div>
                <div className="pool-element-token-pool skeleton"></div>
                <div className="pool-element-token-pool-t skeleton"></div>
                <div className="pool-element-pool-holders skeleton"></div>
              </div>
              <div className="pool-element-3 skeleton"></div>
            </div>
            <div className="pool-element-footer">
              <div className="pool-element-5">
                <div className="pool-element-creater">
                  <div className="pool-element-creater-avatar skeleton"></div>
                  <div className="pool-element-creater-info">
                    <div className="pool-element-creater-name skeleton"></div>
                    <div className="pool-element-creater-time skeleton"></div>
                  </div>
                </div>
              </div>
              <div className="pool-element-4">
                <div className="pool-element-key-stake key skeleton"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        // Рендерим элементы пула из массива данных
        pools.map((pool) => (
          <div key={pool.id} className="pool-element border">
            <div className="pool-element-1">
              <div className="pool-element-2">
                <div className="title-token">{pool.title}</div>
                <div className="pool-element-token-pool">{pool.tokenAmount}</div>
                <div className="pool-element-token-pool-t">{pool.rewardPool}</div>
                <div className="pool-element-pool-holders">
                  <img src={icon_user} className="pool-element-pool-icon-holder" alt="logo" /> {pool.holdersCount}
                </div>
              </div>
              <div className="pool-element-3">
                <img src={pool.logo} className="icon-token pool-element-icon" alt="logo" />
              </div>
            </div>
            <div className="pool-element-footer">
              <div className="pool-element-5">
                <div className="pool-element-creater">
                  <div className="pool-element-creater-avatar">
                    <img src={pool.creatorAvatar} alt="avatar" />
                  </div>
                  <div className="pool-element-creater-info">
                    <div className="pool-element-creater-name">{pool.creatorName}</div>
                    <div className="pool-element-creater-time">{pool.creatorTime}</div>
                  </div>
                </div>
              </div>
              <div className="pool-element-4">
                <Link to="/stake" className="pool-element-key-stake key">
                  STAKE
                </Link>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default PoolList;
