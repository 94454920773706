import React, { useState, useEffect } from 'react';
import starts_1 from '../assets/images/header.png';
import { useNavigate } from 'react-router-dom';
import TopPool from '../components/TopPool';
import PoolList from '../components/PoolList';
import Loader from '../components/Loader';
import icon_fire from '../assets/images/e52bc7dc-1a87-42b3-abda-9c3df00aa42e.gif';

function Home() {
    const navigate = useNavigate(); 
    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.hide();  // Скрываем кнопку "Назад"
    }
    const [Load, setLoad] = useState(true);
    const [LoadClose, setLoadClose] = useState(false);
    const [fadeOut, setFadeOutLoader] = useState(true);
    useEffect(() => {
       
        const timer = setTimeout(() => {
            setFadeOutLoader(false);
            const timerAnim = setTimeout(() => {
                
                setLoadClose(true); // Меняем состояние на false, чтобы скрыть Loader
                setFadeOutLoader(false);
                clearTimeout(timerAnim);
            }, 500); // Таймер на 5 секунд
        }, 1500); // Устанавливаем таймер на 2 секунды

        // Очищаем таймер при размонтировании компонента
        return () => clearTimeout(timer);
    }, []); // Пустой массив зависимостей, чтобы эффект выполнялся только один раз

    const [activeFilter, setActiveFilter] = useState('new');  // Значение 'new' по умолчанию

    // Функция для изменения активного фильтра
    const handleFilterClick = (filter) => {
        setActiveFilter(filter);  // Обновляем активный фильтр
    };
    const handleNewPoolClick = () => {
        navigate('/new-pool'); // Переход на страницу создания нового пула
    };
    
        window.Telegram.WebApp.MainButton.hide(); // Скрываем стандартную кнопку
        setTimeout(() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        }, 500); // Устанавливаем таймер на 2 секунды
    return (
        <div className="App">
            <div>
                {/* Условие для применения классов с анимацией */}
                {/* Задаем условие для отображения loader только в момент анимации */}
                {!LoadClose && (
                    <div className={`loader ${fadeOut ? 'fade-in' : 'fade-out'}`}>
                        <Loader />
                    </div>
                )}
            </div>
            <header className="App-header">
                <img src={starts_1} alt="logo" />
            </header>
            <div className="container">
                <div className="header-home">
                    <div className="title-page pool-title">POOL</div>
                    <div className="key_add_pool" onClick={handleNewPoolClick}>New Pool</div>
                </div>
                <div className="top-pool">
                    <TopPool />
                </div>
            </div>
            <div className="item-pool">
                <div className="container">
                    <div className="container-filter">
                        {/* Обработчик клика для фильтров */}
                        <div
                            className={`filter-pool-element filter-pool-element-new ${activeFilter === 'new' ? 'active' : ''}`}
                            onClick={() => handleFilterClick('new')}
                        >
                            New
                        </div>
                        <div
                            className={`filter-pool-element ${activeFilter === 'hot' ? 'active' : ''}`}
                            onClick={() => handleFilterClick('hot')}
                        >
                            <img src={icon_fire} alt="logo" />
                            Hot
                        </div>
                        <div
                            className={`filter-pool-element ${activeFilter === 'my' ? 'active' : ''}`}
                            onClick={() => handleFilterClick('my')}
                        >
                            My
                        </div>
                    </div>
                    <div className="container-pool">
                        <PoolList activeFilter={activeFilter} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
